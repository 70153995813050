























import mixins from 'vue-typed-mixins'
import DialogMixins from '@/components/dialogs/mixins/DialogMixins'
export default mixins(DialogMixins).extend({
  name: 'ConfirmationDialog'
})
